import axios from 'axios';
import fragments from "@/api/storefront/fragments";
import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

export const DEFAULT_COUNTRY = 'US';
export const STOREFRONT_VERSION = "2024-01";
export const STOREFRONT_ACCESS_TOKEN = "4c2039f2964592cdd53e9bb2cd05a270";

const client = axios.create({
    baseURL: `https://${window?.Shopify?.shop || 'lashifyusa.myshopify.com'}`,
    headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
    }
});

const country_code = window?.Shopify?.country_code?.toUpperCase();

const getFragments = (query, variables = {}, fields = {}) => {
    const fragmentsMatches = [
        ...(query?.matchAll(/\.\.\.(?<name>([\s]+)?[\w]+Fragment)/gm) || []),
    ];

    if (!fragmentsMatches) {
        return [];
    }

    if (!fragmentsMatches?.length) {
        return [];
    }

    return fragmentsMatches?.reduce((fragmentsStrings, fragmentsMatch) => {
        const groups = fragmentsMatch?.groups;
        if (!groups) {
            return fragmentsStrings;
        }

        const name = groups?.name;
        if (!name) {
            return fragmentsStrings;
        }

        const fragment = fragments[name];
        if (!fragment) {
            return fragmentsStrings;
        }

        const fragmentString = typeof fragment === 'function' ?  fragment(variables, fields) : fragment;
        if (!fragmentString) {
            return fragmentsStrings;
        }

        return [
            ...(getFragments(fragmentString, variables, fields) || []),
            fragmentString,
            ...(fragmentsStrings || []),
        ];
    }, []) || [];
};

const prependQueryWithFragments = (query, variables = {}, fields = {}) => {
    const contextQuery = query?.replace("@inContext", `@inContext(country: ${[
        variables?.context?.country,
        variables?.country,
        country_code,
        DEFAULT_COUNTRY
    ]?.filter((country) => {
        return !!country;
    })?.shift() || DEFAULT_COUNTRY})`);

    if (!contextQuery) {
        return query;
    }

    const fragements = getFragments(query, variables, fields);
    if (!fragements) {
        return contextQuery;
    }

    if (!fragements?.length) {
        return contextQuery;
    }

    const uniqueFragments = [
        ...new Set(fragements),
    ];

    if (!uniqueFragments) {
        return contextQuery;
    }

    if (!uniqueFragments?.length) {
        return contextQuery;
    }

    return `${uniqueFragments?.join("\n") || ''}\n${contextQuery}`;
};

export const request = async (query, variables = {}, fields = {}) => {
    const constructedQuery = prependQueryWithFragments(query, variables, fields).replace(/^\s*$/gm, '');

    try {
        const response = await client?.post(`/api/${STOREFRONT_VERSION}/graphql.json`, JSON.stringify({
            query: constructedQuery,
            variables
        }))?.then((response) => {
            return response?.data;
        });

        const { errors, data } = response;

        try {
            Bugsnag.leaveBreadcrumb(`[${PREFIX}] Storefront API response`, {
                url: `/api/${STOREFRONT_VERSION}/graphql.json`,
                method: "POST",
                query: constructedQuery,
                variables,
                response,
                errors,
            }, 'log');
        } catch (error) {}

        if (!errors) {
            return data;
        }

        if (!errors?.length) {
            return data;
        }

        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Storefront request with errors`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    url: `/api/${STOREFRONT_VERSION}/graphql.json`,
                    method: "POST",
                    query: constructedQuery,
                    variables,
                    response,
                    errors,
                });
            });
        } catch (error) {}

        return data;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Storefront error`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    url: `/api/${STOREFRONT_VERSION}/graphql.json`,
                    query: constructedQuery,
                    variables,
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export default {
    client,
    getFragments,
    request,
};
