import Cookies from "js-cookie";

export const CART_COOKIE_NAME = `cart`;

export const getCartToken = () => Cookies?.get(CART_COOKIE_NAME) || null;

export const isCartTokenUsefulForStorefront = (token) => {
    if (!token) {
        return false;
    }

    return !!token?.startsWith('c1-') || false;
};

// token?.toLowerCase() -> Cart Not Found
export const convertCartTokenForStorefront = (token) => `gid://shopify/Cart/${token}`;

export const isCartExist = () => !!getCartToken();

export default {
    CART_COOKIE_NAME,
    getCartToken,
    isCartTokenUsefulForStorefront,
    convertCartTokenForStorefront,
    isCartExist,
};
