import axios from 'axios';
import qs from 'qs';

import { routes } from '@/core/objects';

import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

import { getCartToken, convertCartTokenForStorefront } from '@/api/helpers/cart';
import { getCart as getCartGraphQL, DEFAULT_COUNTRY } from '@/api/storefront';

const CART_VARIABLES_DEFAULT = {
    country: DEFAULT_COUNTRY
};

const CART_FIELDS_DEFAULT = {
    id: true,
    attributes: true,
    checkoutUrl: false,
    cost: {
        checkoutChargeAmount: true,
        subtotalAmount: true,
        subtotalAmountEstimated: true,
        totalAmount: true,
        totalAmountEstimated: true,
        totalDutyAmount: true,
        totalDutyAmountEstimated: true,
        totalTaxAmount: true,
        totalTaxAmountEstimated: true,
    },
    discountAllocations: true,
    discountCodes: true,
    updatedAt: false,
    lines: {
        merchandise: {
            availableForSale: false,
            barcode: false,
            compareAtPrice: true,
            id: true,
            image: true,
            price: true,
            quantityAvailable: false,
            requiresShipping: false,
            selectedOptions: false,
            sku: false,
            title: true,
            weight: false,
            weightUnit: false,
            metafields: true,
            product: {
                id: true,
                availableForSale: false,
                createdAt: false,
                description: false,
                descriptionHtml: false,
                featuredImage: true,
                handle: true,
                isGiftCard: false,
                onlineStoreUrl: false,
                options: false,
                productType: false,
                publishedAt: false,
                requiresSellingPlan: false,
                sellingPlanGroups: false,
                seo: false,
                tags: true,
                title: true,
                totalInventory: false,
                updatedAt: false,
                vendor: false,
                images: false,
                media: false,
                variants: false,
                metafields: false,
            }
        }
    },
    note: false,
    totalQuantity: true,
    buyerIdentity: false,
};

const client = axios.create({
    headers: {
        Accept: '*/*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest'
    },
    params: {
        action: 'minicart',
    }
});

export function prepareBody(data) {
    return qs.stringify(data);
}

export async function getCart({ params = {} } = {
    params: {}
}) {
    try {
        const response = await axios.get(`${routes('root_url') || '/'}cart.js`, {
            params: {
                action: 'minicart',
                ...params,
            },
        });

        if (!response) {
            throw new Error("Empty response error");
        }

        const data = response?.data;
        if (!data) {
            throw new Error("Empty response error");
        }

        try {
            Bugsnag.leaveBreadcrumb(`[${PREFIX}] Cart API response`, {
                url: `${routes('root_url') || '/'}cart.js`,
                method: "GET",
                params,
                response,
            }, 'log');
        } catch (error) {}

        return data;
    } catch(error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to get cart`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    url: `${routes('root_url') || '/'}cart.js`,
                    method: "GET",
                    params,
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
}

export async function getCartStorefront(variables = null, fields = null, force = false) {
    const token = getCartToken();
    
    if (!token) {
        return null;
    }

    const id = convertCartTokenForStorefront(token);
    const cart_variables = variables || CART_VARIABLES_DEFAULT;
    const cart_fields = fields || CART_FIELDS_DEFAULT;

    try {
        const cart = await getCartGraphQL(id, cart_variables, cart_fields, force);
        return cart;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to get cart`), (event) => {
                event.severity = 'error';
            });
        } catch (error) {}
    }

    return null;
}

export async function addItems(items = []) {
    try {
        if (!items) {
            return [];
        }

        if (!items?.length) {
            return [];
        }

        const response = await client.post(`${routes('root_url') || '/'}cart/add.js`, prepareBody({
            items: items?.map((item) => ({
                ...item,
                properties: {
                    ...(item?.properties || {}),
                }
            })) || []
        }), {
            params: {
                action: 'minicart',
            }
        });

        try {
            Bugsnag.leaveBreadcrumb(`[${PREFIX}] Cart API response`, {
                url: `${routes('root_url') || '/'}cart/add.js`,
                method: "POST",
                body: prepareBody({
                    items: items?.map((item) => ({
                        ...item,
                        properties: {
                            ...(item?.properties || {}),
                        }
                    })) || []
                }),
                params: {
                    action: 'minicart',
                },
                response,
            }, 'log');
        } catch (error) {}

        return response;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to add items to cart`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    url: `${routes('root_url') || '/'}cart/add.js`,
                    method: "POST",
                    body: prepareBody({
                        items: items?.map((item) => ({
                            ...item,
                            properties: {
                                ...(item?.properties || {}),
                            }
                        })) || []
                    }),
                    params: {
                        action: 'minicart',
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
}

export async function addItem(item) {
    try {
        return addItems([item])?.then((response) => {
            return response?.data;
        });
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to add item to cart`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    item,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
}

export async function changeItem(newItem) {
    try {
        const response = await client?.post(`${routes('root_url') || '/'}cart/change.js`, prepareBody(newItem))?.then((response) => {
            return response?.data;
        }, {
            params: {
                action: 'minicart',
            }
        });

        try {
            Bugsnag.leaveBreadcrumb(`[${PREFIX}] Cart API response`, {
                url: `${routes('root_url') || '/'}cart/change.js`,
                method: "POST",
                body: prepareBody(newItem),
                params: {
                    action: 'minicart',
                },
                response,
            }, 'log');
        } catch (error) {}

        return response;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to change item in cart`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    url: `${routes('root_url') || '/'}cart/change.js`,
                    method: "POST",
                    body: prepareBody(newItem),
                    params: {
                        action: 'minicart',
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
}

/**
 * Changes items based on the provided changes.
 * @param {Array<any>} changes - An array of changes to be made.
 * @returns {Promise<any>} - A promise that resolves to the result of the changes made.
 */
export async function changeItems(changes = []) {
    try {
        if (!changes) {
            return null;
        }

        if (!changes?.length) {
            return null;
        }

        let response = null;


        for (const change of changes) {
            response = await changeItem(change);
        }

        return response;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to change items in cart`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    changes,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
}

/**
 * Clears the cart by sending a request to the server.
 *
 * @return {Promise<Object>} A Promise that resolves with the response data from the server.
 */
export async function clearCart() {
    try {
        const response = await client?.post(`${routes('root_url') || '/'}cart/clear.js`, {
            params: {
                action: 'minicart',
            }
        })?.then((response) => {
            return response?.data;
        });

        try {
            Bugsnag.leaveBreadcrumb(`[${PREFIX}] Cart API response`, {
                url: `${routes('root_url') || '/'}cart/clear.js`,
                method: "POST",
                params: {
                    action: 'minicart',
                },
                response,
            }, 'log');
        } catch (error) {}

        return response;
    } catch(error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to clear cart items`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    url: `${routes('root_url') || '/'}cart/clear.js`,
                    method: "POST",
                    params: {
                        action: 'minicart',
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
}

export async function changeQuantity(key, quantity) {
    try {
        return changeItem({
            id: key,
            quantity
        });
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to change quantity`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    key,
                    quantity,
                    response: error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
}

export async function removeItem(key) {
    try {
        return changeQuantity(key, 0);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to remove item from cart`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    key,
                    response: error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
}

export async function update(updates) {
    try {
        const response = await client.post(`${routes('root_url') || '/'}cart/update.js`, prepareBody({
            updates
        })).then((response) => {
            return response.data;
        }, {
            params: {
                action: 'minicart',
            }
        });

        try {
            Bugsnag.leaveBreadcrumb(`[${PREFIX}] Cart API response`, {
                url: `${routes('root_url') || '/'}cart/update.js`,
                method: "POST",
                body: prepareBody({
                    updates
                }),
                params: {
                    action: 'minicart',
                },
                response,
            }, 'log');
        } catch (error) {}

        return response;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to update cart`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    url: `${routes('root_url') || '/'}cart/update.js`,
                    method: "POST",
                    body: prepareBody({
                        updates
                    }),
                    params: {
                        action: 'minicart',
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
}

export async function updateAttributes(attributes) {
    try {
        const response = await client.post(`${routes('root_url') || '/'}cart/update.js`, prepareBody({
            attributes
        })).then((response) => {
            return response.data;
        }, {
            params: {
                action: 'minicart',
            }
        });

        try {
            Bugsnag.leaveBreadcrumb(`[${PREFIX}] Cart API response`, {
                url: `${routes('root_url') || '/'}cart/update.js`,
                method: "POST",
                body: prepareBody({
                    attributes
                }),
                params: {
                    action: 'minicart',
                },
                response,
            }, 'log');
        } catch (error) {}

        return response;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to update cart attributes`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    url: `${routes('root_url') || '/'}cart/update.js`,
                    method: "POST",
                    body: prepareBody({
                        attributes
                    }),
                    params: {
                        action: 'minicart',
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
}

export async function getSettings(handle = 'minicart') {
    return axios.get(`/pages/settings/${handle}`, {
        params: {
            view: 'json',
        }
    }).then((response) => {
        return response.data;
    });
}

export default {
    client,
    getCart,
    getCartStorefront,
    addItem,
    addItems,
    changeItem,
    changeItems,
    clearCart,
    changeQuantity,
    removeItem,
    update,
    updateAttributes
};
