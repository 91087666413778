import ls from 'localstorage-slim';

import { request } from '@/api/storefrontClient';

// products
import { QUERY as QUERY_PRODUCT_BY_ID } from "@/api/storefront/queries/products/get-product-by-id";
import { QUERY as QUERY_PRODUCT_BY_HANDLE } from "@/api/storefront/queries/products/get-product-by-handle";
import { QUERY as QUERY_PRODUCTS_BY_IDS } from "@/api/storefront/queries/products/get-product-by-ids";
import { QUERY as QUERY_ALL_PRODUCTS } from "@/api/storefront/queries/products/get-all-products";

// metaobjects
import { QUERY as QUERY_ALL_COLORS } from "@/api/storefront/queries/metaobjects/all-colors";
import { QUERY as QUERY_ALL_STYLES } from "@/api/storefront/queries/metaobjects/all-styles";
import { QUERY as QUERY_GET_BOX } from "@/api/storefront/queries/box/get-box";

// cart
import { QUERY as QUERY_GET_CART } from "@/api/storefront/queries/cart/get-cart";
import { QUERY as MUTATION_CREATE_CART } from "@/api/storefront/queries/cart/create-cart";
import { QUERY as MUTATION_ADD_CART_LINES } from "@/api/storefront/queries/cart/add-cart-lines";
import { QUERY as MUTATION_UPDATE_CART_LINES } from "@/api/storefront/queries/cart/update-cart-lines";
import { QUERY as MUTATION_REMOVE_CART_LINES } from "@/api/storefront/queries/cart/remove-cart-lines";
import { QUERY as MUTATION_ATTRIBUTES_UPDATE } from "@/api/storefront/queries/cart/attributes-update";
import { QUERY as MUTATION_UPDATE_BAYER_IDENTITY } from "@/api/storefront/queries/cart/update-buyer-identity";
import { QUERY as MUTATION_NOTE_UPDATE } from "@/api/storefront/queries/cart/update-note";

// customer
import { QUERY as MUTATION_CUSTOMER_RECOVER } from "@/api/storefront/queries/customer/recover";

import convertProductIdToGraphQL from "@/api/storefront/convert/convertProductIdToGraphQL";
import convertGraphQLProductToShopify from "@/api/storefront/convert/convertGraphQLProductToShopify";
import convertGraphQLBoxMetaobjectToShopify from "@/api/storefront/convert/convertGraphQLBoxMetaobjectToShopify";
import convertGraphQLColorMetaobjectToShopify from "@/api/storefront/convert/convertGraphQLColorMetaobjectToShopify";
import convertGraphQLStyleMetaobjectToShopify from "@/api/storefront/convert/convertGraphQLStyleMetaobjectToShopify";
import { convertCartTokenForStorefront } from "@/api/helpers/cart";
import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

export const DEFAULT_COUNTRY = 'US';

export const requestProductById = async (id, ...params) => {
    const [variables= {}, fields = {}] = params;
    const query= QUERY_PRODUCT_BY_ID(variables, fields);

    try {
        const { product } = await request(query, {
            ...(variables || {}),
            id: convertProductIdToGraphQL(id)
        }, fields || {});

        if (!product) {
            try {
                Bugsnag.leaveBreadcrumb(`[${PREFIX}] Query product by id returns empty response`, {
                    query,
                    variables: {
                        ...(variables || {}),
                        id: convertProductIdToGraphQL(id)
                    },
                }, 'log');
            } catch (error) {}

            return null;
        }

        const convertedProduct = convertGraphQLProductToShopify(product);
        if (!convertedProduct) {
            try {
                Bugsnag.leaveBreadcrumb(`[${PREFIX}] Query product by id returns empty response`, {
                    query,
                    variables: {
                        ...(variables || {}),
                        id: convertProductIdToGraphQL(id)
                    },
                }, 'log');
            } catch (error) {}

            return null;
        }

        return convertedProduct;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to get product by id`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    query,
                    variables: {
                        ...(variables || {}),
                        id: convertProductIdToGraphQL(id)
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const getProductById = async (id,  ...params) => {
    return requestProductById(id, ...params);
};

export const requestProductByHandle = async (handle, ...params) => {
    const [variables = {
        identifiers: [],
        variant_identifiers: [],
    }, fields = {}] = params;

    const query= QUERY_PRODUCT_BY_HANDLE(variables, fields);

    try {
        const { product } = await request(query, {
            ...(variables || {}),
            handle
        }, fields);

        if (!product) {
            try {
                Bugsnag.leaveBreadcrumb(`[${PREFIX}] Query product by handle returns empty response`, {
                    query,
                    variables: {
                        ...(variables || {}),
                        handle,
                    },
                }, 'log');
            } catch (error) {}

            return null;
        }

        const convertedProduct = convertGraphQLProductToShopify(product);
        if (!convertedProduct) {
            try {
                Bugsnag.leaveBreadcrumb(`[${PREFIX}] Query product by handle returns empty response`, {
                    query,
                    variables: {
                        ...(variables || {}),
                        handle,
                    },
                }, 'log');
            } catch (error) {}

            return null;
        }

        return convertedProduct;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to get product by handle`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    query,
                    variables: {
                        ...(variables || {}),
                        handle
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const getProductByHandle = async (handle, ...params) => {
    return requestProductByHandle(handle, ...params);
};

export const getProductsByIds = async (ids = [], ...params) => {
    const [variables = {
        identifiers: [],
        variant_identifiers: [],
    }, fields = {}] = params;

    const query = QUERY_PRODUCTS_BY_IDS(variables, fields);

    try {
        const {
            nodes = []
        } = await request(query, {
            ...(variables || {}),
            ids: (ids || [])?.map((id) => {
                return convertProductIdToGraphQL(id);
            }) || []
        }) || {
            nodes: []
        };

        if (!nodes) {
            return [];
        }

        if (!nodes?.length) {
            return [];
        }

        return (nodes || [])?.map((node) => {
            return convertGraphQLProductToShopify(node);
        });
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to get products by ids`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    query,
                    variables: {
                        ...(variables || {}),
                        ids: (ids || [])?.map((id) => {
                            return convertProductIdToGraphQL(id);
                        }) || []
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const getBulkProductsByIds = async (ids = [], ...params) => {
    const [variables = {
        identifiers: [],
        variant_identifiers: [],
    }, fields = {}] = params;

    return Promise.all(ids?.map((id) => {
        return getProductById(id, variables, fields);
    }) || []);
};

export const getAllProductsPage = async (params = {
    limit: 50,
    before: null,
    after: null,
    identifiers: []
}) => {
    const query = QUERY_ALL_PRODUCTS(params);

    try {
        const { products } = await request(query, {
            ...params,
        });

        if (!products) {
            return [];
        }

        const { edges, pageInfo } = products;
        if (!edges) {
            return [];
        }

        if (!edges?.length) {
            return [];
        }

        let cursor = null;
        let allProducts = [];

        edges?.forEach((edge) => {
            const product = edge?.node;
            if (!product) {
                return;
            }

            const convertedProduct = convertGraphQLProductToShopify(product);
            if (!convertedProduct) {
                return;
            }

            allProducts = [
                ...allProducts,
                convertedProduct
            ];

            cursor = edge?.cursor;
        });

        return {
            products: allProducts,
            pageInfo,
            cursor
        };
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to get all Storefront products`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    query,
                    params,
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const getProducts = async (params = {
    limit: 50,
    before: null,
    after: null,
    identifiers: []
}) => {
    const { products } = await getAllProductsPage(params);
    if (!products) {
        return [];
    }

    if (!products?.length) {
        return [];
    }

    return products;
};

export const getAllProducts = async (params = {
    limit: 50,
    before: null,
    after: null,
    identifiers: []
}) => {
    const { products, pageInfo, cursor } = await getAllProductsPage(params);

    let allProducts = products ?? [];

    let hasNextPage = pageInfo?.hasNextPage;
    let nextPageCursor = cursor;

    while (hasNextPage && nextPageCursor) {
        const { products, pageInfo, cursor } = await getAllProductsPage({
            ...params,
            after: nextPageCursor
        });

        allProducts = [...allProducts, ...products];

        nextPageCursor = cursor;
        hasNextPage = pageInfo?.hasNextPage;
    }

    return allProducts;
};

export const requestBox = async (handle, variables = {}, fields = {}) => {
    const query = QUERY_GET_BOX(variables, fields);

    try {
        const { metaobject } = await request(query, {
            ...(variables || {}),
            handle: {
                type: 'box',
                handle
            },
        });

        if (!metaobject) {
            try {
                Bugsnag.leaveBreadcrumb(`[${PREFIX}] Query box by handle returns empty response`, {
                    query,
                    variables: {
                        type: 'box',
                        handle
                    },
                }, 'log');
            } catch (error) {}

            return null;
        }

        const convertedBox = convertGraphQLBoxMetaobjectToShopify(metaobject);
        if (!convertedBox) {
            try {
                Bugsnag.leaveBreadcrumb(`[${PREFIX}] Query box by handle returns empty response`, {
                    query,
                    variables: {
                        type: 'box',
                        handle
                    },
                }, 'log');
            } catch (error) {}

            return null;
        }

        return convertedBox;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to fetch box`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    query,
                    variables: {
                        ...(variables || {}),
                        handle: {
                            type: 'box',
                            handle
                        },
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        return [];
    }
};

export const getBox = async (handle, variables = {}, fields = {}) => {
    try {
        let cached_box = ls?.get(`box:${handle}`);
        if (!cached_box) {
            cached_box = await requestBox(handle, variables, fields);

            try {
                ls?.set(`box:${handle}`, cached_box, {
                    ttl: 3600,
                });
            } catch (error) {}
        }

        return cached_box;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to fetch box`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const requestColors = async () => {
    const query = QUERY_ALL_COLORS();

    try {
        const { metaobjects } = await request(query);
        if (!metaobjects) {
            return [];
        }

        return metaobjects?.edges?.map((edge) => {
            return edge?.node;
        })?.map((node) => {
            return convertGraphQLColorMetaobjectToShopify(node);
        });
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to box colors`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    query,
                    variables: {},
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        return [];
    }
};

export const getColors = async () => {
    try {
        let cached_colors = ls.get('colors');
        if (!cached_colors) {
            cached_colors = await requestColors();

            try {
                ls?.set('colors', cached_colors, {
                    ttl: 3600
                });
            } catch (error) {}
        }

        return cached_colors;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to box colors`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}
    }

    return [];
};

export const requestStyles = async () => {
    const query = QUERY_ALL_STYLES();

    try {
        const { metaobjects } = await request(query);
        if (!metaobjects) {
            return [];
        }

        return metaobjects?.edges?.map((edge) => {
            return edge?.node;
        })?.map((node) => {
            return convertGraphQLStyleMetaobjectToShopify(node);
        });
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to box styles`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    query,
                    variables: {},
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        return [];
    }
};

export const getStyles = async () => {
    try {
        let cached_styles = ls.get('styles');
        if (!cached_styles) {
            cached_styles = await requestStyles();

            try {
                ls?.set('styles', cached_styles, {
                    ttl: 3600
                });
            } catch (error) {}
        }

        return cached_styles;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to box styles`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        return [];
    }
};

export const resetPassword = async (email) => {
    const query = MUTATION_CUSTOMER_RECOVER();

    try {
        const {
            customerRecover = {
                customerUserErrors: []
            }
        } = await request(query, {
            email
        });

        if (!customerRecover) {
            return [
                {
                    code: 'UNKNOWN',
                    field: 'form',
                    message: 'Something went wrong'
                }
            ];
        }

        const { customerUserErrors } = customerRecover;
        if (!customerUserErrors) {
            return [];
        }

        if (!customerUserErrors?.length) {
            return [];
        }

        return customerUserErrors;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable reset password`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    query,
                    variables: {
                        email
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        return [];
    }
};

export const requestCart = async (id, variables = {}, fields = {}) => {
    const query = QUERY_GET_CART(variables, fields);

    try {
        const { cart } = await request(query, {
            ...variables,
            id
        });

        if (!cart) {
            throw new Error('Cart not found');
        }

        return cart;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to load Storefront cart`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    query,
                    variables: {
                        ...variables,
                        id
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const getCart = async (id, variables = {}, fields = {}, force = false) => {
    if (force) {
        return requestCart(id, variables, fields);
    }

    let cached_cart = ls.get(`cart:${id}`);
    if (!cached_cart) {
        cached_cart = await requestCart(id, variables, fields);

        try {
            ls?.set(`cart:${id}`, cached_cart, {
                ttl: 300,
            });
        } catch (error) {}
    }

    return cached_cart;
};

export const getCartByToken = async (token, variables = {}) => {
    if (!token) {
        throw new Error('Cart not found');
    }

    const id = convertCartTokenForStorefront(token);
    if (!id) {
        return null;
    }

    return getCart(id, variables);
};

export const createCart = async (input, variables = {}, fields = {}) => {
    const query = MUTATION_CREATE_CART(variables, fields);

    try {
        const { cartCreate } = await request(query, {
            ...(variables || {}),
            input
        });

        if (!cartCreate) {
            throw new Error('Cart not found');
        }

        const { cart, userErrors } = cartCreate;

        if (!cart) {
            throw new Error('Cart not found');
        }

        if (userErrors && userErrors?.length) {
            throw new Error(JSON.stringify(userErrors));
        }

        return cart;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to create Storefront cart`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    query,
                    variables: {
                        ...(variables || {}),
                        input
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const addCartLines = async (cartId, lines = [], variables = {}, fields = {}) => {
    const query = MUTATION_ADD_CART_LINES(variables, fields);

    try {
        const { cartLinesAdd } = await request(query, {
            ...variables,
            cartId,
            lines,
        });

        if (!cartLinesAdd) {
            throw new Error('Cart not found');
        }

        const { cart, userErrors } = cartLinesAdd;

        if (!cart) {
            throw new Error('Cart not found');
        }

        if (userErrors && userErrors?.length) {
            throw new Error(JSON.stringify(userErrors));
        }

        try {
            ls?.set(`cart:${cartId}`, cart, {
                ttl: 300,
            });
        } catch (error) {}

        return cart;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to add Storefront cart lines`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    query,
                    variables: {
                        ...variables,
                        cartId,
                        lines,
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const updateCartLines = async (
    cartId,
    lines = [],
    variables = {},
    fields = {}
) => {
    const query = MUTATION_UPDATE_CART_LINES(variables, fields);

    try {
        const { cartLinesUpdate } = await request(query, {
            ...variables,
            cartId,
            lines,
        });

        if (!cartLinesUpdate) {
            throw new Error('Cart not found');
        }

        const { cart, userErrors } = cartLinesUpdate;

        if (!cart) {
            throw new Error('Cart not found');
        }

        if (!!userErrors?.length) {
            throw new Error(JSON.stringify(userErrors));
        }

        try {
            ls?.set(`cart:${cartId}`, cart, {
                ttl: 300,
            });
        } catch (error) {}

        return cart;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to update Storefront cart lines`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    query,
                    variables: {
                        ...variables,
                        cartId,
                        lines,
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const removeCartLines = async (
    cartId,
    lineIds = [],
    variables = {},
    fields = {}
) => {
    const query = MUTATION_REMOVE_CART_LINES(variables, fields);

    try {
        const { cartLinesRemove } = await request(query, {
            ...variables,
            cartId,
            lineIds,
        });

        if (!cartLinesRemove) {
            throw new Error('Cart not found');
        }

        const { cart, userErrors } = cartLinesRemove;

        if (!cart) {
            throw new Error('Cart not found');
        }

        if (!!userErrors?.length) {
            throw new Error(JSON.stringify(userErrors));
        }

        try {
            ls?.set(`cart:${cartId}`, cart, {
                ttl: 300,
            });
        } catch (error) {}

        return cart;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to remove Storefront cart lines`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    query,
                    variables: {
                        ...variables,
                        cartId,
                        lineIds,
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const attributesUpdate = async (
    cartId,
    attributes = [],
    variables = {},
    fields = {}
) => {
    const query = MUTATION_ATTRIBUTES_UPDATE(variables, fields);

    try {
        const { cartAttributesUpdate } = await request(query, {
            ...variables,
            cartId,
            attributes,
        });

        if (!cartAttributesUpdate) {
            throw new Error('Cart not found');
        }

        const { cart, userErrors } = cartAttributesUpdate;
        if (!cart) {
            throw new Error('Cart not found');
        }

        if (!!userErrors?.length) {
            throw new Error(JSON.stringify(userErrors));
        }

        try {
            ls?.set(`cart:${cartId}`, cart, {
                ttl: 300,
            });
        } catch (error) {}

        return cart;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to update Storefront cart attributes`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    query,
                    variables: {
                        ...variables,
                        cartId,
                        attributes,
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const updateCartBuyerIdentity = async (
    cartId,
    buyerIdentity,
    variables = {},
    fields = {}
) => {
    const query = MUTATION_UPDATE_BAYER_IDENTITY(variables, fields);

    try {
        const { cartBuyerIdentityUpdate } = await request(query, {
            ...variables,
            cartId,
            buyerIdentity,
        });

        if (!cartBuyerIdentityUpdate) {
            throw new Error('Cart not found');
        }

        const { cart, userErrors } = cartBuyerIdentityUpdate;

        if (!cart) {
            throw new Error('Cart not found');
        }

        if (!!userErrors?.length) {
            throw new Error(JSON.stringify(userErrors));
        }

        try {
            ls?.set(`cart:${cartId}`, cart, {
                ttl: 300,
            });
        } catch (error) {}

        return cart;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to update Storefront cart buyer identity`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    query,
                    variables: {
                        ...variables,
                        cartId,
                        buyerIdentity,
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const updateCartNote = async (
    cartId,
    note,
    variables = {},
    fields = {}
) => {
    const query = MUTATION_NOTE_UPDATE(variables, fields);

    try {
        const { cartNoteUpdate } = await request(query, {
            ...variables,
            cartId,
            note,
        });

        if (!cartNoteUpdate) {
            throw new Error('Cart not found');
        }

        const { cart, userErrors } = cartNoteUpdate;

        if (!cart) {
            throw new Error('Cart not found');
        }

        if (userErrors && userErrors?.length) {
            throw new Error(JSON.stringify(userErrors));
        }

        try {
            ls?.set(`cart:${cartId}`, cart, {
                ttl: 300,
            });
        } catch (error) {}

        return cart;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to update Storefront cart note`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    query,
                    variables: {
                        ...variables,
                        cartId,
                        note,
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

const QUERY_SHOP_METAFIELD = `query Shop ($namespace: String!, $key: String!) {
  shop {
    metafield(namespace: $namespace, key: $key) {
      value
    }
  }
}`;

// export const getShopMetafield(variables = {}) =>  {
//     return request(QUERY_SHOP_METAFIELD, variables).then((response) => {
//         return response?.shop?.metafield?.value;
//     });
// }

export const getShopMetafield = async ({
    namespace,
    key
}) => {
    const response = await request(QUERY_SHOP_METAFIELD, {
        namespace,
        key,
    });

    console.log('response', response);

    return response;
};

export const getSettings = async () => {
    const response = await getShopMetafield({
        namespace: "absolute",
        key: "settings",
    }).then((response) => {
        return response?.shop?.metafield?.value;
    });

    try {
        return JSON.parse(response);
    } catch (error) {}

    return null;
};

export default {
    // requests
    request,
    requestProductById,
    requestBox,
    requestStyles,
    requestColors,

    // products
    getProductById,
    getProductByHandle,
    getProductsByIds,
    getProducts,
    getAllProducts,

    // metaobjects
    getBox,
    getColors,
    getStyles,

    // getShopMetafield

    // cart
    getCart,
    getCartByToken,
    createCart,
    addCartLines,
    updateCartLines,
    removeCartLines,
    updateCartBuyerIdentity,
    attributesUpdate,
    updateCartNote,

    // customer
    resetPassword,
};
